@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?fbtanq');
  src:  url('fonts/icomoon.eot?fbtanq#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fbtanq') format('truetype'),
    url('fonts/icomoon.woff?fbtanq') format('woff'),
    url('fonts/icomoon.svg?fbtanq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-adjustments:before {
  content: "\e900";
}
.icon-ai:before {
  content: "\e901";
}
.icon-analytics:before {
  content: "\e90b";
}
.icon-cards:before {
  content: "\e90c";
}
.icon-carriers:before {
  content: "\e90d";
}
.icon-cloud:before {
  content: "\e90e";
}
.icon-console:before {
  content: "\e90f";
}
.icon-customers:before {
  content: "\e910";
}
.icon-destinations:before {
  content: "\e911";
}
.icon-expenses:before {
  content: "\e912";
}
.icon-folder:before {
  content: "\e913";
}
.icon-globe:before {
  content: "\e916";
}
.icon-home:before {
  content: "\e917";
}
.icon-instances:before {
  content: "\e918";
}
.icon-journal:before {
  content: "\e919";
}
.icon-layers:before {
  content: "\e91a";
}
.icon-link:before {
  content: "\e91d";
}
.icon-message:before {
  content: "\e91e";
}
.icon-numbers:before {
  content: "\e91f";
}
.icon-payments:before {
  content: "\e920";
}
.icon-people:before {
  content: "\e921";
}
.icon-phone:before {
  content: "\e922";
}
.icon-pricing:before {
  content: "\e923";
}
.icon-purchase:before {
  content: "\e924";
}
.icon-routing:before {
  content: "\e925";
}
.icon-salesforce:before {
  content: "\e926";
}
.icon-settings:before {
  content: "\e927";
}
.icon-space:before {
  content: "\e928";
}
.icon-stt:before {
  content: "\e929";
}
.icon-tts:before {
  content: "\e92a";
}
.icon-vendors:before {
  content: "\e92b";
}
.icon-webhook:before {
  content: "\e92c";
}
