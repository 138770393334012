.copier {
  display: flex;
  align-items: center;
  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: all 300ms ease;
    border-radius: 2px;
    &.withScroll {
      // overflow: auto;
      // text-overflow: unset;
    }
  }
  &__action {
    margin: 0;
    color: #6646e5;
    margin-left: 8px;
    cursor: pointer;
    position: relative;
    z-index: 999;
    opacity: 0;
    &::before {
      content: 'Copied';
      position: absolute;
      left: -28px;
      opacity: 0;
    }
  }
  &__dots {
    display: block;
    font-size: 0.5em;
  }

  &.secure {
    .copier__text {
      display: none;
    }
  }

  &:hover,
  &.persist {
    .copier__action {
      opacity: 1;
    }
    &.secure {
      .copier__dots {
        display: none;
      }
      .copier__text {
        display: block;
      }
    }
  }
  &.copied {
    .copier__text {
      background-color: rgba($color: #6646e5, $alpha: 0.12);
    }
    .copier__action {
      opacity: 1;
      &:before {
        animation: 600ms copy-animation ease-in-out;
        padding-left: 28px;
        width: 62px;
      }
    }
  }
}

@keyframes copy-animation {
  0% {
    transform: translateY(-10px);
    opacity: 0.7;
  }

  50% {
    transform: translateY(-20px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(-30px);
    opacity: 0;
  }
}
