@import './base';

html {
  overflow-y: scroll;
}

.app-container {
  .app-container {
    min-height: 0;
    padding-top: 0;
  }
}

.empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 136px);
  .card {
    box-shadow: none;
    border: 0;
  }
}

.app-container-new {
  background: #f7f7f7;
  .navbar {
    width: 280px;
  }
  .navbar + .app-wrapper {
    padding-left: 280px;
  }
}
.table {
  z-index: 0;
}
.table-head.sticky {
  position: unset;
  z-index: 0 !important;
}
.right-modal {
  right: 0 !important;
  top: 0 !important;
  height: 100vh;
  max-height: 100vh;
  transform: unset;
  left: unset !important;
  border-radius: 0;
}
.right-modal.modal-box-animation {
  &-origin {
    &-enter {
      opacity: 0;
      transform: translateY(0);
      &-active {
        top: 0 !important;
        right: 0 !important;
        opacity: 1;
        transform: translateX(0);
        transition: all 300ms ease-in;
      }
    }
    &-exit {
      opacity: 1;
      transform: translateY(-50%);
      &-active {
        opacity: 0;
        transform: translate(50%);
        transition: all 300ms ease-out;
      }
      &:not(.modal-box-animation-origin-exit-active) {
        left: 50% !important;
        top: 50% !important;
      }
    }
  }
}

.page-padding {
  padding: 20px;
}
.btn--slate-grey.btn--ghost {
  color: black !important;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.border-header-bottom {
  border-bottom: 1px rgba(0, 0, 51, 0.1) solid;
}

.pricing-btn-space {
  display: flex;
  align-items: center;
  height: 36px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 16px;
  cursor: pointer;
  img {
    margin-right: 4px;
  }
}

.pricing-modal-price-container {
  display: flex;
  align-items: center;
  height: 64px;
  width: 419px;
  justify-content: space-between;
}

.pricing-product-name {
  display: flex;
  align-items: center;
}

.pricing-label {
  font-size: 13px;
  color: #000000;
  opacity: 0.6;
}
.pricing-name-container {
  margin-left: 16px;
}

.backdrop-container {
  width: 100vw;
  position: fixed;
  height: calc(100vh);
  z-index: 12000;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.backdrop-form {
  .tabs {
    height: unset;
  }
  position: absolute;
  right: 0;
  width: 480px;
  background-color: white;
  height: 100%;
  padding: 20px;
}
.pricing-modal-header {
  font-size: 22px;
  color: black;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .kit-icon-close {
    font-weight: normal;
    font-size: 15px;
    cursor: pointer;
    color: gray;
    &:hover {
      color: red;
    }
  }
}

.inside-modal {
  padding: 40px;
}

@import 'samespace-ui-kit/scss/_base.scss';

.space-plan-item {
  flex-wrap: wrap;
  @include transition(0.6s, $easeOutQuart);
  &-product {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 20;
    & > span + span {
      flex: 1;
      margin-left: 0.5rem;
    }
    .skeleton {
      .react-loading-skeleton {
        display: block;
      }
    }
  }
  &-form {
    width: 100%;
  }
  &.active {
    margin: -2px -1rem 2px;
    padding: 1rem;
    background: $inverse-color;
    box-shadow: 0px 0px 1px 0 rgba(0, 0, 0, 0.4),
      0px 0px 10px 0 rgba(0, 0, 0, 0.04), 0px 0px 64px rgba(0, 0, 0, 0.08);
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 8px;
    position: relative;
    z-index: 1000;
    .plan-view {
      height: 400px;
      @include transition(0.6s);
    }
    .table-cell {
      opacity: 0;
      &:first-child {
        opacity: 1;
      }
    }
    &:hover {
      background: $inverse-color;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }
  .table-cell {
    align-items: flex-start !important;
    @include transition(0.6s, $easeOutQuart);
  }
}

.company-select {
  font-size: 14px !important;
  li {
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    text-transform: capitalize;
    cursor: pointer;
    @include transition();
    &:hover {
      color: color(--primary-accent);
    }
    &.active {
      color: color(--primary-accent);
      background: color(--primary-accent, 0.1);
    }
  }
}

.space-plan-form {
  width: 100%;
  padding: 1rem;
  &__toggle {
    margin-top: -0.5rem;
  }
  &__body {
    .wrapper {
      margin-bottom: 1.5rem;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 1.5rem;
    border-top: 1px color(--base-color, 0.05) solid;
    .btn {
      width: 120px;
      & + .btn {
        margin-left: 1rem;
      }
    }
  }
}

.space-plan-mode {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  max-width: 720px;
  margin: 0 0 1.5rem;
  &-toggle {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1rem auto;
    align-content: start;
    flex: 1;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    border: 2px color(--base-color, 0.05) solid;
    @include transition();
    .radio {
      margin-top: 1px;
    }
    .text {
      flex: 1;
      margin: 2px 0 0;
      &__title {
        @include transition();
      }
    }
    &.active {
      border-color: color(--primary-accent);
      .text {
        &__title {
          color: color(--primary-accent);
        }
      }
    }
  }
}

.tier-form {
  &__inputs {
    display: grid;
    gap: 1rem;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
    margin: 0 0 1rem;
    .input-box {
      margin-top: 0;
    }
  }
  &-action {
    display: inline-flex;
    color: color(--primary-accent);
    span {
      margin-right: 0.5rem;
    }
  }
}
.voip-header {
  margin-top: 56px;
  padding: 8px;
  border-bottom: 1px rgba(var(--base-color), 0.05) solid;
}
.voip-text {
  font-size: 16px;
  color: black;
  margin: 20px;
}
.viewrates {
  color: #1024f0;
  margin-left: 15px;
  display: inline-block;
}

.company-select {
  font-size: 14px;
  li:hover {
    background: rgba(16, 66, 240, 0.08);
    border-radius: 8px;
    color: #6646e5;
    cursor: pointer;
  }
}
.Popover-body {
  padding: 0.5rem;
}
.popover-heading {
  font-size: 24px;
  &:hover {
    cursor: pointer;
  }
}
.plan-arrow {
  position: absolute;
  font-size: 12px;
  top: 7px;
  margin-left: 7px;
  transition: 0.3s;
  &.active {
    transform: rotate(-180deg);
  }
}
.spacing-type {
  margin-right: 12px;
}

.pricing-form-container {
  padding: 1rem;
}
.form-submit-pricing {
  padding-top: 20px;
  margin-top: 24px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  justify-content: flex-end;
  .btn:first-of-type {
    margin-right: 24px;
  }
}

.premium-features-header {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: -0.23px;
  color: #000000;
  margin-top: 24px;
}

.tiers-container {
  margin-top: 24px;
  margin-bottom: 24px;
}
.total-units {
  font-size: 15px;
  letter-spacing: -0.23px;
  align-self: center;
}

.pricing-unit-style {
  color: grey;
}
.pricing-grid-view {
  display: grid;
  position: relative;
  padding: 20px;
  padding-right: 40px;
  grid-template-columns: 25% 75%;
  grid-column-gap: 20px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.602941px 10.8529px rgba(0, 0, 0, 0.0325794),
    0px 0.250431px 4.50776px rgba(0, 0, 0, 0.025),
    0px 0.090576px 1.63037px rgba(0, 0, 0, 0.0174206);
  border-radius: 8px;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  height: calc(95vh - 56px);
  overflow: hidden;
}
.pricing-grid-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pricing-head {
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -0.23px;
    color: #000000;
    padding-left: 4px;
  }
}
.pricing-plans-view {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
}
.pricing-view {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  padding: 0px 20px;
}
.company-select-container {
  margin-top: 24px;
  margin-bottom: 16px;
}

.plans-container {
  padding: 8px 12px;
  margin-bottom: 16px;
  cursor: pointer;
  letter-spacing: -0.23px;
  &:hover {
    background: #f9f9f9;
    border-radius: 8px;
  }
  .plan-name {
    font-size: 15px;
  }
  .plan-desc {
    color: #727272;
    font-size: 13px;
  }
}
.plans-active {
  background: rgba(#6646e5, 0.08);
  border-radius: 4px;
  .plan-desc {
    color: #000000;
  }
}
.no-plan {
  color: #727272;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: 50vh;
  margin-left: 5%;
}

.details-plan-header {
  font-weight: bold;
  font-size: 15px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-change-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .name {
    margin: 0 9px;
  }
}

.company-select-header {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  color: #1024f0;
  .kit-icon-arrow-down {
    color: gray;
    font-size: 7px;
    margin-left: 4px;
  }
}

.grey-text {
  color: rgba(#000000, 0.6);
}

.voip-header-details {
  font-size: 15px !important;
  color: #000000;
  > span {
    font-size: 15px !important;
    margin-left: 8px;
    color: rgba(#000000, 0.4);
  }
}

.allow-default-container {
  display: flex;
  align-items: center;
}
.inbound-form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  div {
    font-size: 20px;
    color: #000000;
  }
  span:hover {
    cursor: pointer;
    color: red;
  }
}

.clickable {
  cursor: pointer;
}

.toggle-align {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.description-add {
  color: #6646e5;
  font-size: 13px;
  cursor: pointer;
  margin-top: 8px;
}

.plan-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid rgba(#000000, 0.08);
  margin-top: 12px;
  .plan-list-header {
    margin-top: 12px;
    font-size: 15px;
    color: #000000;
    font-weight: bold;
  }
  .plan-list-description {
    font-size: 13px;
    text-align: center;
    color: rgba(#000000, 0.6);
  }
  .add-button-list {
    cursor: pointer;
    background: #6646e5;
    border-radius: 4px;
    padding: 6px 16px;
    margin-top: 24px;
    color: white;
  }
  .add-plan-image {
    height: 120px;
    width: 118px;
  }
}

.product-add-form-header {
  font-size: 20px;
  color: black;
  margin-bottom: 24px;
}
.back-button {
  color: #6646e5;
  cursor: pointer;
  font-size: 13px;
}
.close-icon-container {
  position: absolute;
  right: 40px;
  top: 10px;
  font-size: 16px;
}

.product-form-padding {
  margin-top: 24px;
  margin-bottom: 24px;
}

.products-list-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 20px 0px;
  position: relative;
  .kit-icon-close {
    opacity: 0;
  }
  &:hover {
    .kit-icon-close {
      opacity: 1;
    }
  }
}
.products-list-holder-price {
  display: flex;
  align-items: center;
  .kit-icon-close {
    margin-left: 12px;
  }
}
.products-list-item-name {
  display: flex;
  align-items: center;
  .name {
    margin-left: 8px;
  }
}

.products-list-heading {
  background: #f9f9f9;
  border-radius: 4px;
  color: black;
  font-weight: bold;
  padding: 8px;
  margin-top: 24px;
}

.add-another-product {
  color: #6646e5;
  font-size: 15px;
  cursor: pointer;
  margin-top: 12px;
}

.form-submit-pricing-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  margin-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.table-body {
  .table-row:nth-of-type(2n) {
    background-color: #f9f9f9;
  }
}

.pricing-modal-bottom-buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
  button:nth-of-type(2) {
    margin-left: 5%;
  }
}

.table-with-flag {
  display: flex;
  align-items: center;
  .field {
    margin-left: 8px;
  }
}
.priv-container {
  padding: 20px;
}

.priv-header {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 24px;
}

.priv-grid-view {
  display: grid;
  grid-template-columns: 140px 140px;
  grid-gap: 15px;
}

.priv-item {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  img {
    margin-right: 9px;
  }
  .kit-icon-close {
    font-size: 12px;
    margin-right: 9px;
  }
}
.disabled-priv {
  opacity: 0.6;
}
.company-form-divider {
  background: #f9f9f9;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 15px;
  margin-bottom: 16px;
  font-weight: bold;
}

.company-products-container {
  margin-bottom: 40px;
}

.shift {
  margin-top: 16px;
}

.another-field-company {
  color: #6646e5;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 12px;
}
.additional-field-label {
  color: #727272;
  font-size: 13px;
}

.company-options-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.non-clickable {
  cursor: not-allowed;
  .table-row:hover:not(.no-hover) {
    background-color: white;
  }
}

.company-row-height {
  .table-row {
    height: 70px;
  }
}

.plan-label-heading {
  color: rgba(#000000, 0.4);
  font-size: 13px;
  text-transform: capitalize;
}
.plan-change-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    margin: 0;
    padding: 0;
  }
  .change {
    color: #6646e5;
    cursor: pointer;
  }
}
.modal-button-pricing {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  button:first-of-type {
    margin-right: 8px;
  }
}
.onboarding-header {
  font-size: 22px;
  margin-bottom: 22px;
  padding-bottom: 22px;
}
.onboarding-close {
  display: flex;
  justify-content: flex-end;
  .kit-icon-close {
    color: rgba(#000000, 0.6);
  }
}

.onboarding-form {
  width: 480px !important;
}

.onboarding-form-divider {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #000033;
  padding-bottom: 8px;
  margin-bottom: 24px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-overflow-container-loading {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.form-overflow-container {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 60px;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
}
.sticky-modal-header {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 1000;
}

.dropzone-header {
  color: rgba(0, 0, 51, 0.5);
  font-size: 12px;
  margin-bottom: 8px;
}
.dropzone-points {
  @media screen and (max-width: 736px) {
    padding-left: 20px;
  }
  list-style-image: url('/assets/icons/newIcons/hyphen.svg');
  li {
    font-size: 12px;
    margin-bottom: 8px;
    color: #000033;
    span {
      position: relative;
      top: 3px;
    }
  }
}
.form-submit-onboarding {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: white;
  width: 440px;
  height: 60px;
  padding-top: 20px;
  z-index: 10000;
}
.disabled-head {
  opacity: 0.6;
  font-weight: normal;
}
.pricing-modal-price-container-actions {
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.price-container-space {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
}

.onboarding-cost-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  margin-top: 24px;
  padding: 12px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  .checkbox {
    margin: 0;
  }
}
.margin-remover {
  margin-top: 0px;
}
.margin-top-divider {
  margin-top: 40px;
}
.payment-checkbox {
  padding: 12px 0;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  .checkbox {
    margin: 0px;
  }
  label.payment-checkbox-head {
    display: flex;
    justify-content: space-between;
    margin: 0 0 4px;
    line-height: 1rem;
    font-weight: 400;
    font-size: 13px;
    text-transform: unset;
    color: #666;
  }
}
.checkout-divide {
  margin: 1rem 0;
}
.voip-class {
  display: flex;
  font-size: 15px;
  font-weight: normal;
  .voip-class-item:first-of-type {
    margin-right: 24px;
  }
  .voip-class-item {
    cursor: pointer;
  }
  .active {
    color: #6646e5;
  }
}
.destination-array {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  .destination-name {
    margin-left: 8px;
  }
}
.destination-container {
  margin-top: 24px;
  margin-bottom: 24px;
}
.destination-modal-form-container {
  width: 400px;
}
.inbound-form-container {
  position: absolute;
  width: 100%;
  z-index: 1000000;
  left: 0;
  right: 0;
}

.no-data {
  text-align: center;
  margin-top: 12px;
}
.origin-rates-click {
  color: rgba(0, 87, 255, 1);
  font-size: 15px;
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;
}
.destination-origin {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flag-fixed-top {
  z-index: 1000;
  padding: 20px 0px;
  position: sticky;
  top: -35px;
  background: white;
}
.form-action-voip-fixed {
  position: fixed;
  bottom: 0px;
}
.flow-page-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  padding: 0 32px;
  @media screen and (max-width: 736px) {
    padding: 0 12px;
  }
}
.nav-help {
  color: rgba(#000033, 0.3);
  font-size: 14px;
  text-decoration: underline;
}

.voip-terms-container {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.voip-terms-width {
  width: 1200px;
}
.setup-voip {
  color: #6646e5;
  font-size: 14px;
}

.voip-terms-head {
  font-size: 22px;
  margin-top: 8px;
}
.voip-terms-desc {
  color: #727272;
  font-size: 13px;
  width: 50%;
}
.voip-table-header {
  margin-top: 40px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(black, 0.05);
  padding-bottom: 14px;
}

.ready-buy {
  font-weight: 600;
  font-size: 18px;
  margin-top: 40px;
}
.ready-buy-desc {
  color: #727272;
  font-size: 13px;
}

.terms-list {
  li {
    margin-bottom: 20px;
    font-size: 14px;
    color: #000033;
    a {
      color: blue;
      text-decoration: underline;
    }
  }
  li:before {
    content: '–';
    position: absolute;
    margin-left: -1em;
    color: rgba(#000033, 0.4);
  }
}
.payment-terms {
  margin-top: 24px;
  color: rgba(0, 0, 51, 0.5);
  font-size: 12px;
  li {
    margin-bottom: 20px;
    font-weight: 400;
  }
}

.flow-continue-button {
  width: 100%;
  padding: 14px 0;
  border-radius: 8px;
  margin-top: 40px;
  color: white;
  background: #6646e5;
  border: none;
  outline: none;
  cursor: pointer;
  &-disabled {
    opacity: 0.5;
  }
}
.voip-continue {
  margin-top: 12px !important;
}

.price-container-edit {
  margin-top: 12px;
  margin-bottom: 24px;
}

.new-price-container-edit {
  margin-top: 3px;
  margin-bottom: 6px;
}

.activate-container {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.activate-header {
  font-size: 22px;
  margin-top: 40px;
}

.activate-header-desc {
  font-size: 15px;
  color: rgba(black, 0.6);
  margin-bottom: 24px;
}
.activate-button {
  background: #6646e5;
  border-radius: 4px;
  color: white;
  font-size: 15px;
  padding: 8px 24px;
  cursor: pointer;
}
.verify-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verify {
  color: #6646e5;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.verify-popover {
  padding: 20px;
  width: 280px;
}
.verify-btn {
  margin-top: 16px;
  text-align: center;
  width: 100%;
  background-color: #6646e5;
  color: white;
  padding: 8px 0px;
  cursor: pointer;
  border-radius: 4px;
}

.verified-line-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.verified-line {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.tabs-with-button {
  display: flex;
  height: 100%;
  align-items: center;
  .more-options {
    margin-left: 12px;
    transform: scale(1);
  }
}
.company-file-upload {
  input[type='file'] {
    display: none;
  }
  .file-upload-company {
    color: #6646e5;
    margin-top: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    img {
      margin-right: 4px;
    }
    cursor: pointer;
  }
}
.quota-container {
  margin: 32px;
  width: 540px;
  padding: 40px !important;
}
.capitalize-text {
  text-transform: capitalize;
}
.description-styling {
  font-style: italic;
  color: rgba(black, 0.6);
}
.tabs {
  padding: 0 0 16px;
}
.quota-progress {
  width: 160px;
  height: 4px;
  background-color: rgba(#000000, 0.1);
  border-radius: 8px;
}
.quota-daily-bar {
  position: absolute;
  height: 4px;
  z-index: 10;
  background: #ff9500;
  border-radius: 8px 0px 0px 8px;
}

.quota-usage {
  font-size: 14px;
  margin-top: 26px;
}

.quota-month-bar {
  position: absolute;
  height: 4px;
  z-index: 10;
  background: #6646e5;
  border-radius: 8px 0px 0px 8px;
}
.temp-quota {
  color: #6646e5;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 24px;
  cursor: pointer;
}
.set-alert-container {
  margin-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.threshold {
  display: flex;
  align-items: center;
  .threshold-label {
    color: rgba(#000000, 0.6);
    font-size: 14px;
    margin-right: 8px;
  }
}
.activity-icon {
  transform: translate(-50%, 0);
  height: 2.25rem;
  width: 2.25rem;
  &:before {
    content: '';
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 50%;
  }
  &:after {
    content: '';
    background: rgba($blue, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 50%;
    z-index: 2;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: relative;
    height: 48%;
    z-index: 1;
    path {
      fill: $blue;
      &.api-key-icon {
        stroke: $blue;
      }
    }
    & > g > rect {
      fill: $blue;
    }
    .fill-element {
      fill: $blue;
    }
  }
  &.red {
    &:after {
      background: rgba($red, 0.1);
    }
    path {
      fill: $red;
    }
    & > g > rect {
      fill: $red;
    }
    .fill-element {
      fill: $red;
    }
  }

  &.green {
    &:after {
      background: rgba($green, 0.1);
    }
    path {
      fill: $green;
    }
    & > g > rect {
      fill: $green;
    }
    .fill-element {
      fill: $green;
    }
  }
}
.activity-log-item {
  display: flex;
  align-items: center;
  padding: 10px 32px;
  border-bottom: 1px solid #dbdbdb;
}
.invites-table {
  .table-row {
    height: 70px;
  }
}

.daily-limit-toggle {
  position: absolute;
  right: -11px;
  top: -11px;
}
.reset-daily-limit {
  font-size: 12px;
  color: #6646e5;
  cursor: pointer;
}

.fix-width-submit {
  button[type='submit'] {
    width: 90px;
  }
  .btn__text {
    margin-left: -1px;
  }
}

.price-subscription-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.big-modal {
  width: 70% !important;
}
.add-destination {
  color: #5856d6;
  font-size: 15px;
  cursor: pointer;
}
.destination-submit-form {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.account-list-navigation {
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    &:hover {
      background-color: #f7f7f7;
      border-radius: 4px;
    }
  }
  img:first-of-type {
    margin-left: 10px;
  }
  span {
    letter-spacing: -0.23px;
    font-weight: 500 !important;
    font-size: 19px !important;
    margin-top: 4px;
  }
}

.kit-icon-close:hover {
  cursor: pointer;
}
.nav-link__icon {
  opacity: 1;
  margin: 0px 8px 0 0;
}

.account-list-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dropdown {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > img,
  div,
  article {
    margin-right: 8px;
  }
  img {
    cursor: pointer;
    margin-right: 8px !important;
    &:last-of-type {
      margin-right: 0px !important;
    }
  }
  .dropdown-drop__wrap {
    margin: unset;
    div {
      margin: unset;
    }
    .dropdown-item > div {
      margin-right: 0px;
    }
  }
}

.account-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 0 20px;
  position: sticky;
  top: 55px;
  z-index: 100;
  background: white;
  border-bottom: 1px rgba(0, 0, 51, 0.1) solid;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.account-list-header + .table {
  .table-head {
    background: white !important;
    z-index: 100 !important;
  }
  .sticky-header-table {
    position: sticky !important;
    top: 123px !important;
    background: white;
    z-index: 100 !important;
  }
}
.account-list-header-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 0 20px;
  position: sticky;
  top: 150px;
  z-index: 100;
  background: white;
  border-bottom: 1px rgba(0, 0, 51, 0.1) solid;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.sticky-top__header {
  top: 180px;
}
.voip-destination__ .account-list-header-tabs + .table {
  .table-head {
    background: white !important;
    z-index: 100 !important;
  }
  .sticky-header-table {
    position: sticky !important;
    top: 218px !important;
    background: white;
    z-index: 100 !important;
  }
}
.filter + .table {
  .table-head {
    background: white !important;
    z-index: 100 !important;
  }
  .sticky-header-table {
    position: sticky !important;
    top: 123px !important;
    background: white;
    z-index: 100 !important;
  }
}
.pdf-row {
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
}
.pdf-note {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pdf-note-header {
  font-size: 14px;
}

.pdf-note-description {
  font-size: 12px;
  color: rgba(#000000, 0.6);
}

.popover-save-btn {
  .btn {
    margin-top: 8px;
    width: 100%;
  }
}

.change-btn-profile {
  .kit-icon-pencil {
    display: none;
    cursor: pointer;
  }
  .shield-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -2px;
  }
  .shield-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -2px;
  }
}
.create-space-container {
  width: 400px !important;
}

.origin-modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.origin-modal {
  display: flex;
  z-index: 5000;
}
.origin-modal-name {
  font-size: 20px;
  margin-right: 12px;
}
.origin-modal-desc {
  color: #959595;
  font-size: 20px;
}

.account-list-filter-container {
  width: 300px;
  padding: 20px;
  .selector-wrapper {
    margin-bottom: 20px;
  }
}

.account-list-filter-actions {
  display: flex;
  justify-content: flex-end;
  button:first-of-type {
    margin-right: 8px;
  }
}

.journal-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.journal-name {
  width: 30ch;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total-show {
  position: fixed;
  height: 60px;
  background-color: white;
  width: calc(100% - 280px);
  bottom: 0;
  z-index: 10000;
  left: 280px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 12%;
  align-items: center;
  .total-label {
    margin-right: 10%;
  }
}

.updated-total-cost {
  .table {
    width: 100%;
  }
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 60px;
  background-color: #fff;
  width: 100%;
  bottom: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
}
.updated-total-label {
  font-size: 16px;
  white-space: nowrap;
  line-height: 24px;
  opacity: 0.6;
}

.add-shadow {
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.08);
}

.total-number {
  color: #1024f0;
  font-weight: bold;
}

.adjustment-show {
  padding-right: 14%;
}

.expense-show {
  padding-right: 16%;
}

.updated-expense-show {
  padding-right: 20%;
}

.updated-payment-show {
  padding-right: 10%;
}

.updated-proformal-show {
  padding-right: 34.5%;
}

.updated-purchases-show {
  padding-right: 13%;
}

.updated-purchases-orders-show {
  padding-right: 37%;
}

.updated-journal-show {
  padding-right: 16px;
}

.journal-amount-wrapper {
  width: 27%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.payment-show {
  padding-right: 11%;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
}
.filter-item {
  color: #1024f0;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: flex-end;
  font-size: 14px;
  margin-top: 7px;
  .filter-pop {
    left: 38px;
    top: -6px;
    z-index: 1;
  }
  span {
    color: rgba(black, 0.3);
    font-size: 7px;
    margin-bottom: 4px;
    margin-left: 4px;
  }
}
.back {
  cursor: pointer;
  color: #6646e5;
  font-size: 12px;
}

.po-list {
  left: -40px;
}

.terms-items {
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.terms-title {
  display: flex;
  align-items: center;
}
.add-terms {
  font-size: 14px;
  color: #6646e5;
  margin-top: 12px;
  cursor: pointer;
}

.terms-name {
  margin-left: 7.5px;
  margin-right: 15px;
}

.terms-page-container {
  width: 700px;
  padding: 10px 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.602941px 10.8529px rgba(0, 0, 0, 0.0325794),
    0px 0.250431px 4.50776px rgba(0, 0, 0, 0.025),
    0px 0.090576px 1.63037px rgba(0, 0, 0, 0.0174206);
  border-radius: 8px;
  margin: 24px 24px 0 24px;
}

.terms-page-header {
  font-size: 18px;
  color: black;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.terms-last-modified {
  font-size: 14px;
  color: rgba(#000000, 0.5);
}

.desc-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hide-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.full-width {
  width: 100%;
}

.table-contact-view {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pdf-row-container {
  .kit-icon-close {
    display: none;
  }
  &:hover {
    .kit-icon-close {
      display: unset;
      &:hover {
        color: red;
      }
    }
  }
}

.analytics-header {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  column-gap: 20px;
}
.analytics-header-book {
  display: grid;
  grid-template-columns: 400px 400px 200px 200px;
  column-gap: 20px;
}
.tds-receive-header {
  grid-template-columns: 1fr 1fr;
}
.analytics-container {
  padding: 20px;
  background: white;
}
.bill-invoice-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendor-contact-row {
  .table-row {
    height: 68px;
  }
}

.call-logs-header {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 24px;
}

.search-by-container {
  display: grid;
  align-content: center;
  grid-template-columns: 200px 200px;
  align-items: end;
  margin-bottom: 20px;
  .form__field {
    margin-bottom: 0 !important;
  }
  .type-filter-input {
    .input-box {
      border-left: none !important;
      outline: none !important;
    }
    input {
      border-left: none !important;
      height: 42px;
      outline: none !important;
    }
  }
}

.signup-container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
}
.signup-hero {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}
.signup-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 32px;
}
.signup-form-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 50%;
}
.create-acc-header {
  font-size: 20px;
  margin-top: 15vh;
  margin-bottom: 42px;
  align-self: flex-start;
}

.signup-fields-container {
  width: 100%;
}

.btn-go {
  margin-top: 32px;
  .btn {
    width: 100%;
  }
}
.copyright-signup {
  position: absolute;
  bottom: 32px;
  left: 63%;
  font-size: 13px;
  color: rgba(#000000, 0.3);
}

.Popover {
  z-index: 100000;
}
.privileges-header {
  margin-top: 56px;
  margin-bottom: 32px;
  font-size: 20px;
}
.privileges-margin {
  margin-bottom: 16px;
  margin-top: 16px;
}
.privileges-table-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  padding-bottom: 16px;
  row-gap: 16px;
  border-bottom: 1px solid rgba(#000000, 0.1);
}
.privileges-table-items {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  padding: 8px 0;
  row-gap: 16px;
  &:hover {
    background: #f7f7f7;
    border-radius: 4px;
  }
}
.privileges-title-padding {
  padding-left: 8px;
}
.privileges-title {
  font-size: 12px;
  color: rgba(#000033, 0.6);
  text-transform: uppercase;
  text-align: center;
  .checkbox {
    margin: 0;
  }
  &-black {
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    text-transform: capitalize;
    justify-self: start;
  }
  &-normal {
    color: rgba(#000033, 0.7);
    font-size: 16px;
    text-align: left;
    justify-self: start;
  }
}

.privileges-modal {
  width: 900px;
}
.card-section {
  padding-bottom: 32px;
}
.popover-click {
  .popover__trigger {
    display: block;
  }
}

.edit-phone-pop {
  width: 400px;
  padding: 20px;
}

.forgot-password {
  position: absolute;
  right: 0px;
  z-index: 100;
  top: -4px;
  font-size: 13px;
  color: #6646e5;
  cursor: pointer;
}
.invoices-dropdown {
  .dropdown-drop {
    max-height: 200px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
.controls-sign {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.account-summary-message {
  text-align: center;
}

.filter-pop {
  width: 15px;
  height: 15px;
  background-color: red;
  color: white;
  border-radius: 40px;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 30px;
  top: -6px;
  z-index: 1;
}

.books-list-search {
  display: flex;
  align-items: center;
  justify-content: center;
  > article {
    margin-right: 16px;
  }
  > div {
    margin-right: 16px;
  }
  > img {
    margin-right: 16px;
  }
  > button {
    margin-right: 16px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
  img {
    cursor: pointer;
    &:last-of-type {
      margin-right: 0px;
    }
  }
  .dropdown-drop__wrap {
    margin: unset;
    div {
      margin: unset;
    }
    .dropdown-item > div {
      margin-right: 0px;
    }
  }
}
.space-items-table {
  .table-body .table-row:nth-of-type(2n).active {
    background-color: white;
  }
}

.filter-pop-util {
  position: fixed !important;
  top: 56px !important;
}
.fflag-0 {
  background-image: url('/assets/img/all.png') !important;
  background-size: cover;
}
.modal-close-util {
  position: absolute;
  right: 10px;
  top: 10px;
}
.account-details {
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid color(--base-color, 0.08);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  .account-details-control {
    display: flex;
    align-items: center;
    .title {
      margin-left: 23px;
      font-weight: 500 !important;
      font-size: 19px !important;
      letter-spacing: -0.23px;
      margin-right: 8px;
    }
  }
}
.table-company-header {
  .table-head {
    position: sticky;
    background-color: white;
  }
  .sticky-header-table {
    position: sticky !important;
    top: 123px !important;
    background: white;
    z-index: 100 !important;
  }
}
.table-company-header-tabs {
  .table-head {
    position: sticky;
    background-color: white;
  }
  .sticky-header-table {
    position: sticky !important;
    top: 218px !important;
    background: white;
    z-index: 100 !important;
  }
  .sticky-top__table-head {
    top: 248px !important;
  }
}
.voip-controls-header {
  display: flex;
  height: 100%;
  align-items: center;
  > div {
    margin-right: 16px;
  }
  > article {
    margin-right: 8px;
  }
  > button {
    margin-right: 16px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
  img {
    cursor: pointer;
    margin-right: 16px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
}
.form-action-voip {
  border-top: 1px solid #ebebf0;
  margin-top: 24px;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  button:first-of-type {
    margin-right: 16px;
  }
  .remove {
    position: absolute;
    left: 44px;
    color: #f73c5d;
    font-size: 15px;
    cursor: pointer;
  }
}
.button-carrier-full {
  button {
    width: 100%;
  }
}

.plus-header {
  margin-top: 5px;
}

.sales-manager-container {
  display: flex;
  align-items: center;
  .manager-name {
    margin-left: 8px;
  }
  margin-bottom: 19px;
}

.sales-manager-header {
  font-size: 20px;
  margin-bottom: 32px;
}

.sales-manager-form-users {
  font-size: 15px;
  margin-top: 40px;
  margin-bottom: 12px;
  font-weight: bold;
}

.sales-user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
  }
  .checkbox {
    margin-bottom: 0;
    z-index: 100;
  }
  .checkbox__input {
    z-index: 1000;
  }
}

.sales-user-name {
  display: flex;
  align-items: center;
  .sales-space {
    margin-left: 8px;
  }
}
.sales-user-name-label {
  font-size: 15px;
}
.sales-user-email {
  color: rgba(#000000, 0.6);
  font-size: 13px;
}

.sales-list-container {
  max-height: 400px;
  overflow: auto;
  margin-top: 40px;
}

.sales-form-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.sales-add-button {
  font-size: 15px;
  color: #1024f0;
  cursor: pointer;
}

.sales-add-filter-container {
  width: 300px;
}

.popover-list-user-container {
  height: 200px;
  margin-top: 12px;
  overflow: auto;
}

.popover-list-user-name {
  margin-left: 8px;
}

.popover-list-user-item {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.popover-add-btn {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  padding: 6px 0px;
  background-color: #1024f0;
  color: white;
  cursor: pointer;
}

.sales-user-item {
  .kit-icon-close {
    opacity: 0;
    font-size: 15px;
    color: rgba(#000000, 0.6);
  }
  &:hover {
    .kit-icon-close {
      opacity: 1;
    }
  }
}
.floating-checkbox {
  position: absolute;
  left: 0;
  top: 5px;
  .checkbox {
    margin-bottom: 0;
  }
}
.additional-container {
  position: relative;
  display: grid;
  grid-template-columns: 150px 150px 10px;
  justify-content: space-around;
  padding-left: 12px;
  margin-bottom: 12px;
}

.delete-additional {
  display: flex;
  align-items: center;
  justify-content: center;
}
.purchase-actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.purchase-actions-container {
  .dropdown {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
  }
}

.display-none {
  display: none;
}

.origin-save {
  position: absolute;
  right: -44px;
  top: 7px;
}

.location-display {
  a {
    color: #6646e5;
  }
}

.demo-instace-header {
  display: flex;
  align-items: center;
  font-size: 19px;
  justify-content: space-between;
  margin-bottom: 40px;
}
.invite-demo-header {
  font-size: 16px;
  margin-top: 32px;
  &__description {
    font-size: 13px;
    color: #6d7175;
    margin-bottom: 16px;
  }
}
.help-message-container {
  background: #f7f7f7;
  border-radius: 7px;
  padding: 12px;
  font-size: 13px;
  line-height: 20px;
}
.market-reset-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .demo-reset {
    color: #f12929;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    opacity: 0;
  }
}

.demo-click {
  &:hover {
    .demo-reset {
      opacity: 1;
    }
  }
}

.side-drawer-controls {
  right: 40px;
  bottom: 0;
  z-index: 10000;
  position: -webkit-sticky;
  position: fixed;
  width: calc(100% - 52px);
  padding-top: 20px;
  padding-bottom: 35px;
  background-color: #fff;
}
.form__submit .btn + .btn {
  margin-left: 24px;
  &:hover {
    background-color: #13054b;
  }
}
.delete-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.delete-text {
  text-align: center;
  margin-bottom: 30px;
}
.form__submit .btn + .btn.pending {
  margin-left: 24px;
  &:hover {
    background-color: #f0f0f0;
  }
}

.close-position-with-shadow {
  box-shadow: 0px 8px 16px rgba(#000000, 0.08);
  height: 68px;
}
.close-position {
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  padding-top: 12px;
  position: relative;
  z-index: 5000;
  position: absolute;
  width: 100%;
  background: white;

  .close-position-btn {
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    &:hover {
      background: #e5e5e5;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    padding: 20px;
    z-index: 12000;
    border-radius: 24px;
    right: 12px;
    top: 12px;
    position: relative;
    .kit-icon-close {
      color: gray;
      position: absolute;
    }
  }
}

.close-position-btn {
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:hover {
    background: #e5e5e5;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  padding: 20px;
  z-index: 12000;
  border-radius: 24px;
  right: 12px;
  top: 12px;
  position: absolute;
  .kit-icon-close {
    color: gray;
    position: absolute;
  }
}

.form-padding {
  padding: 20px;
  padding-left: 40px;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 120px) !important;
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.users-table-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-overflow-container {
  overflow-y: scroll;
}

.overflow-container-privilege {
  overflow-y: scroll;
  position: relative;
  height: 50vh;
}

.company-form-padding {
  overflow-y: auto;
  height: 85vh;
}
.company-form-head {
  position: sticky;
  top: -2px;
  padding-bottom: 10px;
  background: white;
  z-index: 10000;
}
.onboading-checkd {
  .checkbox {
    margin: 0px;
  }
}
.onboarding-form-divider-no-bottom {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #000033;
  padding-bottom: 8px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onboarding-form-divider-m {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #000033;
  padding-bottom: 8px;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
}
.vendors-form-overflow {
  overflow-y: auto;
  max-height: 80vh;
}
.phone-details-overflow {
  max-height: 98vh;
  overflow-y: auto;
}

.new-customer-title {
  font-weight: bold;
  font-size: 21px;
  color: #000033;
  padding: 11px 0;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 51, 0.1);
  margin-top: -28px;
}
.form-sub-header {
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1px solid #000033;
  margin-bottom: 32px;
}
.form-sub-header-with-top {
  margin-top: 48px;
}
.form-sub-header-with-bottom {
  margin-bottom: 4px;
}
.custom-input {
  .selector-wrapper {
    z-index: 1;
  }
  .checkbox {
    font-size: 12px;
    .checkbox__icon {
      height: 12px;
      width: 12px;
    }
  }
  .input-label {
    margin-left: 12px;
    position: absolute;
    z-index: 2;
    margin-top: -8px;
    background: white;
  }
}

.custom-input-label {
  .checkbox {
    font-size: 12px;
    .checkbox__icon {
      height: 12px;
      width: 12px;
    }
  }
  .input-label {
    margin-left: 12px;
    position: absolute;
    z-index: 1;
    margin-top: 13px;
    background: white;
  }
}
.display-none {
  display: none;
}
.product-header {
  font-weight: 600;
  font-size: 11px;
  color: rgba(0, 0, 51, 0.5);
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.product-item-container {
  &:first-of-type {
    border-top: 1px solid rgba(#000033, 0.1);
  }
  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }
  position: relative;
  border-bottom: 1px solid rgba(#000033, 0.1);
  height: 80px;
  display: flex;
  align-items: center;
}
.product-item {
  margin-left: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}
.product-item-details {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .product-name {
    font-weight: 500;
    font-size: 15px;
    color: #000033;
  }
  .product-details {
    color: rgba(0, 0, 51, 0.5);
    font-size: 12px;
  }
}
.products {
  display: flex;
  flex-direction: column;
}
.new-customer-submit {
  margin-top: 30px;
}
.edit-product {
  position: absolute;
  right: 10px;
}
.voice-cloud-options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.voice-cloud-pricing-btn {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 51, 0.1);
  font-weight: 600;
  font-size: 15px;
  color: #000033;
  padding: 8px 16px;
  cursor: pointer;
}
.popover-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}
.popover-product-name {
  margin-left: 8px;
}

.incremental-label {
  .input-box {
    padding-top: 2px !important;
  }
  .input-label {
    margin-top: -6px !important;
  }
}

.setup-cost-container {
  margin: 24px 0 32px 0;
}
.plus-container {
  color: #1a77f2;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  .plus-text {
    margin-left: 8px;
  }
}
.tiers-table {
  border: 0.5px solid rgba(0, 0, 51, 0.1);
  border-collapse: collapse;
  font-size: 15px;
  margin-bottom: 32px;
  padding: 0;
  tr,
  td {
    border: 0.5px solid rgba(0, 0, 51, 0.1);
    border-collapse: collapse;
    padding: 0;
    margin: 0;
    &:first-of-type {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      background-color: #fafafa;
      color: rgba(#000033, 0.4);
    }
    input {
      border: none;
      text-align: right;
      height: 100%;
      width: 100%;
    }
  }
}
.add-tiers-container {
  cursor: pointer;
  font-size: 12px;
  color: #1a77f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  justify-content: flex-end;
}
.metered-checkbox {
  margin-top: 32px;
  margin-bottom: 32px;
}
.pricing-modal-submit-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    &:first-of-type {
      margin-right: 8px;
    }
  }
}
.pricing-modal-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    &:first-of-type {
      margin-right: 24px;
    }
  }
}
.voip-pricing-header {
  font-size: 21px;
  color: black;
  margin: 32px;
}
.right-modal {
  right: 0 !important;
  top: 0 !important;
  height: 100vh;
  max-height: 100vh;
  transform: unset;
  left: unset !important;
  border-radius: 0;
}
.right-modal.modal-box-animation {
  &-origin {
    &-enter {
      opacity: 0;
      transform: translateY(0);
      &-active {
        top: 0 !important;
        right: 0 !important;
        opacity: 1;
        transform: translateX(0);
        transition: all 300ms ease-in;
      }
    }
    &-exit {
      opacity: 1;
      transform: translateY(-50%);
      &-active {
        opacity: 0;
        transform: translate(50%);
        transition: all 300ms ease-out;
      }
      &:not(.modal-box-animation-origin-exit-active) {
        left: 50% !important;
        top: 50% !important;
      }
    }
  }
}
.tabs-underline {
  background: #ebebf0;
  margin: -1px;
}
.form-action {
  border-top: 1px solid #ebebf0;
  margin-top: 24px;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  button:first-of-type {
    margin-right: 16px;
  }
  .remove {
    position: absolute;
    left: 44px;
    color: #f73c5d;
    font-size: 15px;
    cursor: pointer;
  }
}
.empty-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: -1;
  .direction-search {
    font-size: 13px;
    margin-top: 16px;
  }
}
.edit-form {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 100;
  height: fit-content;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 95%;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  animation: voipPop 300ms ease-in;
}
@keyframes voipPop {
  0% {
    opacity: 0;
    transform: scale(0.96);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.edit-form-pop {
  padding: 32px;
}
.flag-name-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  span:last-of-type {
    margin-left: 8px;
    font-size: 15px;
  }
}
.form-sub-heading {
  font-size: 15px;
  line-height: 24px;
  color: black;
  margin-bottom: 8px;
  margin-top: 40px;
  background: rgba(249, 249, 249, 1);
  font-weight: bold;
  border-radius: 4px;
  padding: 6px 10px;
}
.edit-form-local {
  height: 509px;
}
.destination-list {
  margin-top: 24px;
  border-bottom: 1px solid #ebebf0;
  margin-bottom: 24px;
}
.added-item {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
}
.details-added-item {
  display: flex;
  height: 100%;
  align-items: center;
}
.details-added-name {
  margin-left: 4px;
}
.country-name {
  margin-left: 8px;
}
.toll-free {
  height: 237px;
}
.origin-rates {
  color: #6646e5;
  font-size: 15px;
  text-decoration-line: underline;
  cursor: pointer;
}
.tollfree-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-left: 16px;
  }
}
.origin-rates-container {
  background: white;
  .back {
    cursor: pointer;
    color: #6646e5;
    font-size: 12px;
  }
}
.heading-modals {
  font-size: 21px;
  color: black;
  margin-bottom: 16px;
}
.origin-rates-header {
  margin-top: 8px;
  font-size: 21px;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.header-name {
  margin-left: 8px;
}
.onboarding-form-container {
  width: 400px;
  .input-box,
  .selector-wrapper {
    margin-bottom: 28px;
  }
}
.space-name-label {
  .input-box {
    margin-bottom: 0;
  }
  .checkbox {
    margin-bottom: 32px;
  }
  .selector-wrapper {
    margin-bottom: 0;
  }
}
.spacing-input {
  .input-box {
    margin-bottom: 32px;
  }
  .selector-wrapper {
    margin-bottom: 32px;
  }
}
.voice-cloud-btn-disabled {
  pointer-events: none;
  background: #fafafa;
  color: grey;
}

.detail-action-button {
  position: absolute;
  right: -35px;
}
.actions-dial-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-terms-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quill-container {
  padding-bottom: 20px;
  min-width: 700px;
}

.pricingmodal-container {
}

.usage-wrapper {
  padding: 20px;
  padding-bottom: 0;
}
.usage-wrapper-end {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end;
}

.usage-items-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 24px;
}
.usage-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.usage-item-heading {
  color: rgba(0, 0, 51, 0.6);
  font-size: 14px;
}

.usage-item-value {
  color: #000033;
  font-size: 16px;
  text-transform: capitalize;
}
.usage-item-description {
  color: rgba(0, 0, 51, 0.6);
  font-size: 12px;
}

.calendar-wrapper {
  padding: 12px;
}
.usage-info-container {
  margin-left: 16px;
}

.company-header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.overflowing-text {
  width: 5ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.export-button-analytics {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  width: 30px;
  border-radius: 4px;
  height: 100%;
  img {
    margin: 0;
  }
  &:hover {
    background-color: #f7f7f7;
  }
}
.analytics-date {
  display: flex;
  align-items: center;
  margin-right: 0px !important;
  .text-date {
    margin-left: 8px;
  }
}
.analytics-report-date-btn {
  margin-right: 24px;
}

.popup-calendar {
  margin-right: 0px !important;
}
.popup-calendar-logs {
  .calendar-wrapper {
    width: 340px;
  }
}
.calendar-wrapper
  .calendar
  .DayPicker-Day--start:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
  color: white;
}
.calendar-wrapper
  .calendar
  .DayPicker-Day--end:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
  color: white;
}
.DayPicker-Day
  .DayPicker-Day--start
  .DayPicker-Day--end
  .DayPicker-Day--selected {
  border-radius: 50% !important;
}
.gmt-time {
  color: #1024f0;
  margin-right: 24px;
}
.popover-scroll-fix {
  height: 400px;
  overflow: auto;
}
.list-cover {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.602941px 10.8529px rgba(0, 0, 0, 0.0325794),
    0px 0.250431px 4.50776px rgba(0, 0, 0, 0.025),
    0px 0.090576px 1.63037px rgba(0, 0, 0, 0.0174206);
  border-radius: 8px;
  margin: 24px 24px 0 24px;
}
.list-cover-overflow {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.602941px 10.8529px rgba(0, 0, 0, 0.0325794),
    0px 0.250431px 4.50776px rgba(0, 0, 0, 0.025),
    0px 0.090576px 1.63037px rgba(0, 0, 0, 0.0174206);
  border-radius: 8px;
  margin: 24px 24px 0 24px;
}

.list-header {
  h4 {
    font-size: 19px;
  }
  background: white;
  padding: 0 24px;
  padding-top: 20px;
  position: sticky;
  top: 55px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  z-index: 1000;
}
.onboarding-button-invite {
  .form__submit .btn + .btn {
    width: 95px;
  }
}
.sso-white-box {
  width: 442px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.602941px 10.8529px rgba(0, 0, 0, 0.0325794),
    0px 0.250431px 4.50776px rgba(0, 0, 0, 0.025),
    0px 0.090576px 1.63037px rgba(0, 0, 0, 0.0174206);
  border-radius: 8px;
  margin: 24px 0px 0 0px;
  background: white;
  padding: 20px;
}
.table-with-no-options {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.602941px 10.8529px rgba(0, 0, 0, 0.0325794),
    0px 0.250431px 4.50776px rgba(0, 0, 0, 0.025),
    0px 0.090576px 1.63037px rgba(0, 0, 0, 0.0174206);
  border-radius: 8px;
  margin: 24px 24px 0 24px;
}

.company-select-fixed {
  width: 300px;
}

.customer-details-view-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.602941px 10.8529px rgba(0, 0, 0, 0.0325794),
    0px 0.250431px 4.50776px rgba(0, 0, 0, 0.025),
    0px 0.090576px 1.63037px rgba(0, 0, 0, 0.0174206);
  border-radius: 8px;
  margin: 24px 24px 0 24px;
  padding: 20px;
  overflow: auto;
}
.billing-spacing {
  padding: 20px;
}

.recent-trans-options {
  margin-top: 24px;
}
.list-cover-button {
  button {
    padding: 4px 6px !important;
    margin-top: -8px;
    width: 40px;
    height: 40px;
  }
}
.list-cover-button-cal {
  button {
    padding: 4px 6px !important;

    width: 40px;
    height: 40px;
    margin-top: 0px;
  }
}
.list-cover-button-m {
  margin-right: 0px !important;
  button {
    padding: 4px 6px !important;
    width: 40px;
    height: 40px;
    margin-top: 0px;
  }
}

.pop-red {
  position: relative;
}
.heading__item {
  .badge {
    position: absolute;
    top: -7px;
  }
}

.no-overflow {
  overflow: hidden;
}

.calendar-with-date {
  display: flex;
  align-items: center;
  margin-right: 0px !important;
  .text-date {
    margin-left: 8px;
    margin-right: 0px;
  }
}

.update-terms {
  position: absolute;
  left: 100px;
  top: 3px;
  cursor: pointer;
  z-index: 1;
}

.accordion-shadow {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.price-preview-container {
  margin-top: 32px;
}
.price-preview-header {
  font-size: 13px;
  margin-bottom: 8px;
  color: rgba(black, 0.6);
}

.price-preview-box {
  padding: 16px 24px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border-radius: 8px;
  .price-preview-stat:last-of-type {
    margin-bottom: 0px;
  }
  hr {
    margin: 1rem 0;
  }
}
.price-preview-stat {
  font-size: 15px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-name {
    color: rgba(black, 0.6);
  }
}
.modal__close {
  z-index: 100;
}

.clear-filter-analytics {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  z-index: 500;
}

.copy-text {
  z-index: 1000;
}
.caller_id-container {
  display: flex;
  position: relative;
}

.empty-state-container {
  .card {
    background: transparent !important;
  }
  .empty-state {
    background: transparent !important;
  }
}

.allow-edit-style {
  .checkbox {
    margin-top: 8px;
  }
  .checkbox__title {
    font-size: 13px;
    opacity: 0.6;
    font-weight: 600;
    margin: 3px 0px 0px 4px;
  }
}

.upload-form-checkbox {
  margin-top: 16px;
}

.checkbox-header {
  .checkbox {
    margin: 0px;
  }
  display: flex;
  align-items: center;
  margin-top: -6px;
}

.flex-remover {
  grid-template-columns: 1fr;
  row-gap: 12px;
}

.delete-array-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  .kit-icon-close {
    opacity: 0;
  }
  &:hover {
    background-color: #f7f7f7;
    border-radius: 4px;
    .kit-icon-close {
      opacity: 1;
    }
  }
}

.call-logs-table {
  th {
    border-bottom: 1px solid rgba(black, 0.06);
    padding: 12px 1rem;
    z-index: 100;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.4px;
    color: rgba(black, 0.5);
  }
  td {
    font-size: 16px;
    font-weight: 400;
    padding: 1rem;
  }
  .table {
    width: 100%;
  }
  .tab-wrapper {
    position: relative;
    overflow: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    left: 0px;
  }
  .sticky-col-item {
    box-shadow: 2px 0px 0px rgba(0, 0, 0, 0.025);
  }
}

.call-logs-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .form__field {
    width: 200px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.create-space-option-item {
  padding: 8px 4px;
  cursor: pointer;
  &:hover {
    background: rgba(#6646e5, 0.08);
    color: #6646e5;
  }
}

.tier-form-action-modal {
  font-size: 15px;
}

.balance--invoiceId__break-word {
  word-break: break-all;
}

.company-row-hover {
  .dropdown {
    display: none;
  }
  &:hover {
    .dropdown {
      display: unset;
    }
  }
}

.align-right-table-cell {
  .table-cell__content {
    margin-left: auto;
  }
}

.stick-header__table-head {
  position: sticky !important;
  top: 123px !important;
  z-index: 999;
  background-color: white !important;
}

.stick-header__table-head + .table {
  .sticky-header-table {
    position: sticky !important;
    top: 225px !important;
    z-index: 999;
    background-color: white !important;
  }
}

.stick-header__table-head__call-logs {
  position: sticky !important;
  top: 123px !important;
  z-index: 999;
  background-color: white !important;
}

// .sticky-header__call-logs {
//   position: sticky !important;
//   top: 0px !important;
//   z-index: 999;
//   background-color: white !important;
// }

.show-on-hover-only--btn {
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
}

.logs-table-row:hover {
  .show-on-hover-only--btn {
    opacity: 1;
    pointer-events: all;
  }
}

.input-date-form {
  .popover {
    height: 100%;
    .popover__trigger {
      width: 100%;
      height: 100%;
      .input-date-container {
        width: 100%;
        height: 100%;
        .input-date {
          width: 100%;
          height: 40px;
          padding: 9px 12px;
          border: 1px #e6e6e6 solid;
          border-radius: 2px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.dropzone-tab {
  padding-top: 20px;
}
