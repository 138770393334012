@import 'samespace-ui-kit/scss/_base.scss';
.side-drawer {
  &-wrapper {
    background-color: $white;
    position: fixed;
    right: 0;
    top: 55px;
    height: calc(100% - 56px);
    width: 500px;
    min-width: 500px;
    z-index: 12000;
    overscroll-behavior: contain;
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
  }
  &-container {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 1000vh;
    top: 55px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 12000;
  }
}
